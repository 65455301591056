.hero-height {
    height: 840px;
}

.no-hero-height {
    height: 190px;
}

@media (min-width: 640px) { 
    .hero-height {
        height: 980px;
    }
}

@media (min-width: 768px) {
    
}

@media (min-width: 1024px) {
    .hero-height {
        height: 779px;
    }
}

@media (min-width: 1280px) {
    
}

@media (min-width: 1536px) {
    
}